import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './core/guards/auth_guards';
import { MainComponent } from './layout/main/main.component';
import { MainGuard } from './core/guards/main.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'get-started'
  },
  {
    path: 'get-started',
    canActivate: [MainGuard],
    loadComponent: () => import('src/app/layout/home/get-started.component').then(m => m.GetStartedComponent)
  },
  {
    path: 'auth',
    canActivate: [MainGuard],
    loadChildren: () => import('./modules/auth/auth-routes').then(m => m.routes)
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/afterLoginPages/modules-routes').then((m) => m.routes)
      }
    ]
  },
  {
    path: 'public',
    loadChildren: () => import('./modules/public-pages/public-pages-routes').then(m => m.routes),
  },
  {
    path: '**',
    redirectTo: 'enquiry'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, InjectionToken, NO_ERRORS_SCHEMA, OnInit, PLATFORM_ID } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { CommonModule, isPlatformBrowser } from "@angular/common";
import { ToastModule } from "primeng/toast";
import { GoogleMapComponent } from "./shared/components/google-map/google-map.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { HttpClientModule } from "@angular/common/http";
import { UserHeadersComponent } from "./shared/components/headers/user-headers.component";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { NavigationStart, Event as NavigationEvent } from '@angular/router';
import { MessagingService } from "./services/messaging.service";
import { SwUpdateService } from "./services/sw-update.service";
import { assets } from "./core/constants/assets.const";

@Component({
  selector: "logibids-root",
  standalone: true,
  imports: [RouterOutlet, CommonModule, ToastModule, HttpClientModule, GoogleMapComponent,
     NgxUiLoaderModule, TranslateModule, UserHeadersComponent, ConfirmDialogModule],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers:[TranslateService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppComponent implements OnInit {
  title = "logibids-client";
  ltr: boolean;
  rtl: boolean;
  url = '';
  constructor(
    public translate: TranslateService,
    private router: Router,
    private _messagingService: MessagingService,
    private swUpdateService: SwUpdateService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
  ) {
    this.router.events
    .subscribe(
      (event: NavigationEvent) => {
        if (event instanceof NavigationStart) {
          this.url = event.url || '';
        }
      });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this._messagingService.requestPermission();
      this._messagingService.listenForFrontMessages();
      this.translate.get('notificationAlert').subscribe(() => {
        // Check if translation exists and permission is denied
        if (Notification.permission === 'denied' && this.translate.store.translations) {
          alert(this.translate.instant('notificationAlert'));
        }
      });
    }
    this.swUpdateService.checkForUpdates();    
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.action === 'navigate') {
        window.location.href = event.data.url;
        if (event.data.payload.notifications && event.data.payload.notifications.length > 0) {
          this._messagingService.sendMessage(event.data.payload.notifications)
        }
      } else if (event.data && event.data.action === 'playNotificationSound') {
        this.playNotificationSound();
      }
    });
  }

  private playNotificationSound(): void {
    const audio = new Audio(assets.notificationSound); // Adjust path as needed
    audio.play().catch((error) => {
      console.error('Error playing notification sound:', error);
    });
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang; 
  }
  
}

import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class premissionServices  {
  constructor(private router: Router, private _authService:AuthService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    if (this._authService.isAuthenticated()) {
      this.router.navigate(['/enquiry']);
      return false;
    } else {
      return true;
    }
  }
}
export const MainGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(premissionServices).canActivate(next, state);
};


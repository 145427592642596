import { Component, AfterViewInit } from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapMarker } from '@angular/google-maps';  

@Component({
  selector: 'logibids-google-map',
  standalone: true,
  imports: [GoogleMap, GoogleMapsModule, MapMarker],
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements AfterViewInit {
  lat = 0;
  lng = 0;
  zoom = 13;
  center!: google.maps.LatLngLiteral;
  markerPosition!: google.maps.LatLngLiteral;
  options!: google.maps.MapOptions;

  constructor() {}

  async ngAfterViewInit() {
    await this.waitForGoogleMaps();
    this.mapInitializer();
  }

  async waitForGoogleMaps(): Promise<void> {
    return new Promise((resolve) => {
      const checkGoogleMaps = setInterval(() => {
        if (window['google'] && window['google'].maps) {
          clearInterval(checkGoogleMaps);
          resolve();
        }
      }, 100);
    });
  }

  mapInitializer() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      this.center = { lat: this.lat, lng: this.lng };
      this.markerPosition = { lat: this.lat, lng: this.lng };
      
      // Ensure mapId is properly used
      this.options = {
        center: this.center,
        zoom: this.zoom,
        disableDefaultUI: true,
        zoomControl: false,
        streetViewControl: false,
      };  
    }, (error) => {
      console.error('Location error:', error);
    });
  }
}

<p-toast />
<div class="confirm-dilouge">
  <p-confirmDialog></p-confirmDialog>
</div>
<ngx-ui-loader [overlayColor]="'rgba(255,255,255,0.8)'" blur="13" [bgsOpacity]="0.1" fgsType="circle" fgsColor="#000000"
  pbColor="#000000" [pbThickness]="1" />
<logibids-user-headers></logibids-user-headers>
<div class="main_wrp relative">
  @if (url.startsWith('/tracking')) {
  <router-outlet></router-outlet>
  } @else {
  <div class="card_view">
    <router-outlet></router-outlet>
  </div>
  }
  @if (url && !url.startsWith('/tracking')) {
  <logibids-google-map />
  }
</div>
import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { EncryptionService } from './encryption.service';
import { assets } from '../core/constants/assets.const';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  assetsConst = assets;
  private receivedMessageIds = new Set<string>();
  private messageSource = new BehaviorSubject<any>(null);
  public currentMessage = this.messageSource.asObservable();
  constructor(private _encyrption: EncryptionService, private translate: TranslateService) {}

  requestPermission(): void {
    const messaging = getMessaging();

    navigator.serviceWorker.ready
      .then((registration) => {
        // Pass our custom service worker (`combined-sw.js`) instead of `firebase-messaging-sw.js`
        return getToken(messaging, {
          vapidKey: environment.firebaseConfig.vapidKey,
          serviceWorkerRegistration: registration
        });
      })
      .then((currentToken) => {
        if (currentToken) {
          this._encyrption.encryptLocalStorage(currentToken, 'fcmToken');
        } else {
          alert(this.translate.instant('common.fcmNotification'));
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  listenForFrontMessages(): void {
    const messaging = getMessaging();
    if (localStorage[environment.storageKey]) {
      onMessage(messaging, (payload) => {
        const message = payload;
        const messageId = payload.messageId;
  
        if (!messageId) {
          console.warn('Received message without a messageId.');
          return;
        }
  
        if (this.receivedMessageIds.has(messageId)) {
          console.log('Duplicate notification ignored:', messageId);
          return; // Skip duplicate notification
        }
  
        this.receivedMessageIds.add(messageId);
  
        if (message.data) {
          this.playNotificationSound();
          const notificationOptions = {
            body: message.data.body || 'Click to view',
            icon: this.assetsConst.logo,
            data: payload.data
          };
          // Display notification using service worker
          this.displayNotification(message.data.title || 'New Notification', notificationOptions);
        }
      });
    }
  }

  private displayNotification(title: string, options: NotificationOptions): void {
    // Check for service worker registration
    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope')
      .then((registration) => {
        if (registration) {
          // Show notification
          registration.showNotification(title, options);
        } else {
          console.error('Service worker registration not found.');
        }
      });
  }

  private playNotificationSound(): void {
    const audio = new Audio(this.assetsConst.notificationSound); // Adjust path as needed
    audio.play().catch((error) => {
      console.error('Error playing notification sound:', error);
    });
  }

  sendMessage(message: any) {
    this.messageSource.next(message);
  }
}



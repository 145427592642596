<nav class="navbar flex justify-content-between align-items-center">
  <figure class="hd_logo" style="cursor: pointer">
    <img [src]="assetsConst.headerLogo" (click)="gotoHome()" alt="logibids-logo" />
  </figure>
  <ul class="nav_rt flex align-items-center justify-content-end gap-3">
    @if (user!=null) {
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle hover-hand user-icon" (click)="op1.toggle($event)" role="button">
        <figure>
          <img src="{{ user?.photo }}" width="25px" height="25px" *ngIf="user?.photo" /><i
            class="fas fa-user fa-fw userImage" *ngIf="!user?.photo"></i>
        </figure>
      </a>
    </li>
    <li class="nav-item dropdown relative">
      <a #notificationButton class="nav-link dropdown-toggle hover-hand user-icon clr" (click)="openOverlay()" href="javascript:void(0);" role="button">
        <p-button icon="pi pi-bell" [raised]="false" [rounded]="true" severity="danger" [outlined]="false">
        </p-button>
      </a>
      <span class="noti_count" *ngIf="user.notification_unread">{{ user.notification_unread }}</span>
    </li>
    @if (translateService) {
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle hover-hand user-icon blacktext" (click)="op2.toggle($event)" role="button">
        <p-button [icon]="selectedLang ? null : 'pi pi-globe'" [label]="selectedLang ? selectedLang : null"
          [rounded]="true" severity="danger" [outlined]="false">
        </p-button>
      </a>
    </li>
    }
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle hover-hand user-icon clr" (click)="op3.toggle($event)" role="button">
        <p-button icon="pi pi-info-circle" [rounded]="true" severity="danger" [outlined]="false">
        </p-button>
      </a>
    </li>
    }
    <p-overlayPanel #op [style]="{
        width: '426px'
      }" >
      <div class="mn_notification">
        <div class="title" style="position: fixed; top: 10px; font-weight: 600; font-size: 15px">
          <h4>{{ "Notifications" | translate }}</h4>
        </div>
        @if (notifications() && notifications().length > 0) {
          <div class="notification-item1">
            <ul class="" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [scrollWindow]="false" 
            (scrolled)="onScrollingFinished()">
              @for (list of notifications(); track $index; let index = $index) {
                <li (click)="btn_routeNotification(list.payload.type, list.payload.enquiry_id, list.id)">
                  @if (!list.viewed) {
                    <div class="dot"></div> 
                  } 
                  <p>{{list.message}}<span class="block">{{list.created_at | date: 'dd/MM/YYYY hh:mm a'}}</span></p>
                </li>
              }
            </ul>
          </div>
        } @else if (notifications() && notifications().length === 0) {

          <div class="noDataFound">
            <figure>
                <img [src]="assetsConst.noNotification" alt="">
            </figure>
            <p>
                {{ "noNotifications" | translate }}
            </p>
        </div>
        }
     
      </div>
    </p-overlayPanel>
    <p-overlayPanel #op1 class="header_op" [style]="{
        width: '235px',
        'overflow-y': 'scroll',
        'scroll-behavior': 'smooth'
      }" infiniteScroll [infiniteScrollDistance]="20" [infiniteScrollThrottle]="20" [scrollWindow]="false">
      <ul class="profile_drpdwn">
        <li>
          <figure>
            <img [src]="assetsConst.sidebar1" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr" (click)="gotoMyProfile()">{{ "myProfile" | translate }}
          </a>
        </li>

        <li>
          <figure>
            <img [src]="assetsConst.sidebar5" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr" (click)="gotoMyTrips()">{{ "myTrips" | translate }}
          </a>
        </li>

        <li>
          <figure>
            <img [src]="assetsConst.sidebar11" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr" (click)="goToMyWallet()">{{ "Wallet" | translate }}
          </a>
        </li>
        <li>
          <figure>
            <img [src]="assetsConst.sidebar10" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand header-switch newClr"
            style="display: flex; justify-content: space-between; gap: 20px">
            {{ "Notifications" | translate }}
            <p-inputSwitch [(ngModel)]="notification" class="float-right height-0"
              (onChange)="notificationOnOff($event)"></p-inputSwitch>
          </a>
        </li>

        <li>
          <figure>
            <img [src]="assetsConst.sidebar7" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr" (click)="gotoChangePassword()">{{ "changePass" | translate }}</a>
        </li>
        <li  (click)="deleteUser()">
          <figure>
            <img [src]="assetsConst.sidebar8" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr">{{
            "deleteAccount" | translate
            }}</a>
        </li>

        <li>
          <figure>
            <img [src]="assetsConst.sidebar9" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr" (click)="btn_logOut()">{{
            "logout" | translate
            }}</a>
        </li>
      </ul>
    </p-overlayPanel>
    <p-overlayPanel #op2 class="header_op" [style]="{
        width: '235px',
        'overflow-y': 'scroll',
        'scroll-behavior': 'smooth'
      }" infiniteScroll [infiniteScrollDistance]="20" [infiniteScrollThrottle]="20" [scrollWindow]="false">
      <div class="profile_drpdwn">
       
        @for (lang of filteredLanguages; track $index) {
          <li>
        
            <figure>
              <img [src]="assetsConst.sidebar12" alt="svg" />
            </figure>
              <a class="dropdown-item hover-hand newClr" (click)="setLanguage(lang.language_code)">{{ lang.name }}</a>
          </li>
        }
      </div>
    </p-overlayPanel>
    <p-overlayPanel #op3 class="header_op" [style]="{
        width: '235px',
        'overflow-y': 'scroll',
        'scroll-behavior': 'smooth'
      }" infiniteScroll [infiniteScrollDistance]="20" [infiniteScrollThrottle]="20" [scrollWindow]="false">
      <ul class="profile_drpdwn">
        <li>
          <figure>
            <img [src]="assetsConst.Vector1" alt="svg" />
          </figure>
          <a *ngIf="user && user.type !== 'DRIVER'" class="dropdown-item hover-hand newClr fnt"
            (click)="gotoAboutUs()">{{ "aboutUs" | translate }}</a>
        </li>
        <li>
          <figure>
            <img [src]="assetsConst.Vector2" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr fnt" (click)="gotoTerms()">{{
            "termsConditions" | translate
            }}</a>
        </li>
        <li>
          <figure>
            <img [src]="assetsConst.Vector3" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr fnt" (click)="gotoPrivacy()">{{ "privacyPolicy" | translate }}</a>
        </li>
        <li>
          <figure>
            <img [src]="assetsConst.Vector5" alt="svg" />
          </figure>
          <a *ngIf="user && user.type !== 'DRIVER'" class="dropdown-item hover-hand newClr fnt"
            (click)="gotoContact()">{{ "contactUs" | translate }}</a>
        </li>

        <li>
          <figure>
            <img [src]="assetsConst.Vector1" alt="svg" />
          </figure>
          <a class="dropdown-item hover-hand newClr fnt" (click)="gotoFaq()">{{ "Faqs" | translate }}</a>
        </li>

      </ul>
    </p-overlayPanel>
  </ul>
</nav>
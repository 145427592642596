import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from "@angular/core";
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withComponentInputBinding, withInMemoryScrolling, withViewTransitions } from "@angular/router";

import { routes } from "./app.routes";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { InitialService } from "./services/initial.service";
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SetInterceptor } from "./core/interceptors/set-interceptor.service";
import { GetInterceptor } from "./core/interceptors/get-interceptor.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { apiList } from "./services/apiList.service";
import { provideServiceWorker } from "@angular/service-worker";
import { environment } from "src/environments/environment";
import { initializeApp } from 'firebase/app';

initializeApp(environment.firebaseConfig);
export function HttpLoaderFactory(http: HttpClient):TranslateLoader {
  return  new  TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled'
};
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);
type FactoryFunction = () => void;

const swPath = environment.production ? '/logibids/client/combined-sw.js' : '/combined-sw.js';

export function initialServiceFactory(
  initialService: InitialService
): () => Promise<void> {
  return async () => {
    await initialService.setUpGoogleScript(); // Ensure Google Maps is loaded first
    initialService.getLanguages(); // Fetch language data
  };
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withViewTransitions(), withComponentInputBinding(), inMemoryScrollingFeature),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
         defaultLanguage: 'en'
      }),
      NgxUiLoaderModule,
      NgxUiLoaderHttpModule.forRoot({ showForeground: true })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideServiceWorker(swPath, {
      enabled: true,
      registrationStrategy: 'registerWhenStable:3000'
    }),
    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: initialServiceFactory,
      deps: [InitialService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptor,
      multi: true
    },
    MessageService,
    DialogService,
    TranslateService,
    apiList,
    ConfirmationService,
    DynamicDialogRef
  ],
};

/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncryptionService } from '../../services/encryption.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SetInterceptor implements HttpInterceptor {
  encryptedString: any;
  constructor(private _encryptionService: EncryptionService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    return next.handle(clonedReq);
  }

  handleRequest(req: HttpRequest<any>) {
    const token = this.getTokenForRequest();
    const language = localStorage['language'] || 'en';
    const fcmToken = localStorage['fcmToken'] ? this._encryptionService.decryptLocalStorage('fcmToken'): '';
    const modifiedBody = req.body
    let authReq;
    authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': token ? `Bearer ${token}` : '',
        'x-device-type': 'WEB',
        'x-device-token': fcmToken,
        'Accept-Language': language,
        'x-portal': 'CLIENT',
      }),
      body: modifiedBody,
    });

    if (
      (req.method.toLowerCase() === 'post' || 
        req.method.toLowerCase() === 'put') &&
      req.body instanceof FormData
    ) {
      authReq = req.clone({
        headers: new HttpHeaders({
          'authorization': token ? `Bearer ${token}` : '',
          'x-device-type': 'WEB',
          'x-device-token': fcmToken,
          'Accept-Language': language,
          'x-portal': 'CLIENT',
        }),
      });
    }
    return authReq;
  }

  getTokenForRequest(): string | null {
    return (
      this._encryptionService.decryptLocalStorage(environment.storageKey)
    );
  }
}

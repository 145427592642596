/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { ConfirmDialogService } from './confirm-dialog.service';


@Injectable({
  providedIn: 'root'
})
export class SwUpdateService implements OnDestroy {

  $isAnyNewUpdateAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  serviceSubscriptions: Subscription[] = [];
  
  constructor(private swUpdate: SwUpdate, private _confirmationService: ConfirmDialogService, private _translateService: TranslateService) {
  }

  checkForUpdates(): void {
    if (this.swUpdate.isEnabled) {
      this.serviceSubscriptions.push(interval(15 * 1000).subscribe(() => this.swUpdate.checkForUpdate()));
      this.serviceSubscriptions.push(
        this.swUpdate.versionUpdates.subscribe(evt => {
          if (evt.type === 'VERSION_READY') {
            this.$isAnyNewUpdateAvailable.next(true);
            this.reloadWithIcon();
          }
        }),
      );

      this.serviceSubscriptions.push(
        this.swUpdate.unrecoverable.subscribe(evt => {
          this.reloadWithIcon();
        }),
      );
    }
  }

  reloadWithIcon():void {
    this._confirmationService
      .updateVersionConfirmationDialog(
        ' ',
        this._translateService.instant('updateVersionBild')
      )
      .then((result) => {
        if (result) {
          window.location.reload();
        }
      });
  }

  ngOnDestroy(): void {
    this.serviceSubscriptions?.forEach(x => x?.unsubscribe());
  }
}

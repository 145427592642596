import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiList } from './apiList.service';
import { notificationsResponse } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly http: HttpClient,
    private _apiList: apiList
  ) {}

  getNotifications(query: string): Observable<notificationsResponse> {
    return this.http
      .get<{
        data: notificationsResponse;
      }>(`${environment.base_url}${this._apiList.notifications}?${query}`)
      .pipe(map(res => res.data));
  }

  updateNotificationById(id: number): Observable<any> {
    return this.http
      .patch<{
        data: any;
      }>(`${environment.base_url}${this._apiList.notifications}/${id}`, {})
      .pipe(map(res => res.data));
  }
}
